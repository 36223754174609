import React from 'react';

const Sidebar = ({ setShowAssignmentModal, setShowClassModal, setContent, refreshAssignments }) => {
  return (
    <div className="sidebar">
      <div className="create-dropdown">
        <select
          onChange={(e) => {
            if (e.target.value === 'addAssignment') {
              setShowAssignmentModal(true);
            } else if (e.target.value === 'addClass') {
              setShowClassModal(true);
            }
            e.target.selectedIndex = 0; // Reset to the placeholder after selection
          }}
          style={{
            backgroundColor: '#fff', // White background
            border: '1px solid #dcdcdc', // Light grey border
            borderRadius: '50px', // Slightly rounded corners
            cursor: 'pointer',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
            transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out', // Smooth transitions for interactions
            outline: 'none', // Remove the default focus outline
            WebkitAppearance: 'none', // Removes default system styling on some browsers
            MozAppearance: 'none', // Removes default system styling on Firefox
            appearance: 'none', // Standard property to remove default system styling
            color: '#5f6368',
            width: '100%' // Dark grey color for the text similar to Google's design
          }}
          defaultValue=""
          className='drpdwn px-1 py-3 h6' 
        >
          <option value="" disabled hidden>Create ▼</option>
          <option className="drpdwn-item" value="addAssignment">Add Assignment</option>
          <option className="drpdwn-item" value="addClass">Add Class</option>
        </select>
    
        <button
            onClick={() => { setContent('calendar'); refreshAssignments(); }}
            style={{
                backgroundColor: '#fff', // White background
                border: '1px solid #dcdcdc', // Light grey border
                borderRadius: '0px', // Slightly rounded corners
                cursor: 'pointer',
                //boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out', // Smooth transitions for interactions
                outline: 'none', // Remove the default focus outline
                WebkitAppearance: 'none', // Removes default system styling on some browsers
                MozAppearance: 'none', // Removes default system styling on Firefox
                appearance: 'none', // Standard property to remove default system styling
                color: '#5f6368',
                width: '100%' // Dark grey color for the text similar to Google's design
            }}
            className="drpdwn px-1 py-2 h6 mt-3"
        >
            Calendar
        </button>

        <button
            onClick={() => { setContent('dailyPlanner'); refreshAssignments(); }}
            style={{
                backgroundColor: '#fff', // White background
                border: '1px solid #dcdcdc', // Light grey border
                borderRadius: '0px', // Slightly rounded corners
                cursor: 'pointer',
                //boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out', // Smooth transitions for interactions
                outline: 'none', // Remove the default focus outline
                WebkitAppearance: 'none', // Removes default system styling on some browsers
                MozAppearance: 'none', // Removes default system styling on Firefox
                appearance: 'none', // Standard property to remove default system styling
                color: '#5f6368',
                width: '100%' // Dark grey color for the text similar to Google's design
            }}
            className="drpdwn px-1 py-2 h6 mt-2"
        >
            Daily Planner
        </button>
      </div>
      {/* Additional sidebar content can go here */}
    </div>
  );
};

export default Sidebar;